import Upload from "assets/Upload";
import React, {useState} from "react";

const FormCMS = ({formData, setFormData}) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [mirrorIsEnabled, setMirrorIsEnabled] = useState(false);
  const addSection = () => {
    setFormData((prev) => {
      return {
        ...prev,
        form: [
          ...prev.form,
          {
            columns: 1,
            flexDirection: "row",
            fields: [],
          },
        ],
      };
    });
  };

  const updateSection = (index, key, value) => {
    setFormData((prev) => {
      const updatedForm = prev.form.map((section, i) =>
        i === index ? {...section, [key]: value} : section
      );
      return {
        ...prev,
        form: updatedForm,
      };
    });
  };

  const addField = (sectionIndex) => {
    setFormData((prev) => {
      // Clone the form
      const updatedForm = [...(prev.form || [])];

      // Check if the section exists
      if (!updatedForm[sectionIndex]) {
        console.error(`Section at index ${sectionIndex} does not exist.`);
        return prev; // No update if section doesn't exist
      }

      // Clone the fields array and add a new field
      const updatedFields = [
        ...(updatedForm[sectionIndex].fields || []),
        {
          type: "input", // Default type
          label: "",
          placeholder: "",
          required: false,
          value: "",
          options: [], // Ensure options array is initialized
          flexDirection: "column", // Default to column
          name: "",
        },
      ];

      // Update the section
      updatedForm[sectionIndex] = {
        ...updatedForm[sectionIndex],
        fields: updatedFields,
      };

      // Return the updated form data
      return {
        ...prev,
        form: updatedForm,
      };
    });
  };

  const updateField = (sectionIndex, fieldIndex, key, value) => {
    const updatedFormData = formData.form.map((section, sIndex) => {
      if (sIndex === sectionIndex) {
        return {
          ...section,
          fields: section.fields.map((field, fIndex) => {
            if (fIndex === fieldIndex) {
              return {
                ...field,
                [key]: value,
                ...(key === "label" && {name: value.replace(/\s/g, "")}),
              };
            }
            return field;
          }),
        };
      }
      return section;
    });

    setFormData({
      ...formData,
      form: updatedFormData,
    });
  };

  const removeSection = (index) => {
    setFormData((prev) => {
      return {
        ...prev,
        form: prev.form.filter((_, i) => i !== index),
      };
    });
  };

  const removeField = (sectionIndex, fieldIndex) => {
    setFormData((prev) => {
      // Clone the form
      const updatedForm = [...(prev.form || [])];

      // Check if the section exists
      if (!updatedForm[sectionIndex]) {
        console.error(`Section at index ${sectionIndex} does not exist.`);
        return prev; // No update if section doesn't exist
      }

      // Clone the fields array
      const updatedFields = [...(updatedForm[sectionIndex].fields || [])];

      // Check if the field exists
      if (fieldIndex < 0 || fieldIndex >= updatedFields.length) {
        console.error(
          `Field at index ${fieldIndex} does not exist in section ${sectionIndex}.`
        );
        return prev; // No update if the field index is invalid
      }

      // Remove the field
      updatedFields.splice(fieldIndex, 1);

      // Update the section
      updatedForm[sectionIndex] = {
        ...updatedForm[sectionIndex],
        fields: updatedFields,
      };

      // Return the updated form data
      return {
        ...prev,
        form: updatedForm,
      };
    });
  };

  return (
    <div className="py-4 flex w-full flex-col">
      <div className="flex mb-4 w-full justify-between items-center">
        <h1 className="text-md font-medium">Form Editor</h1>

        <div className="flex gap-4">
          <div className="px-2.5 py-1 rounded-full flex justify-center font-semibold  items-center bg-blue-500 text-white  hover:bg-blue-600 cursor-pointer">
            <label htmlFor="file-input">
              <Upload height={16} width={16} />
            </label>
            <input
              type="file"
              accept=".json"
              className="hidden"
              id="file-input"
              onChange={(e) => {
                // JSON file to add to the form data
                const fileReader = new FileReader();

                // Get the json and add to the setFormData
                fileReader.readAsText(e.target.files[0], "UTF-8");
                fileReader.onload = (e) => {
                  console.log("e.target.result", JSON.parse(e.target.result));
                  setFormData(JSON.parse(e.target.result));
                };

                // Reset the input
                e.target.value = "";
              }}
            ></input>
          </div>
          <button
            onClick={addSection}
            className="px-2.5 py-1 rounded-full flex justify-center font-medium  items-center bg-blue-500 text-white  hover:bg-blue-600"
          >
            +
          </button>
          <button
            className="font-semibold text-lg"
            style={{
              transform: isFormOpen ? "rotate(90deg)" : "rotate(0deg)",
              transition: "transform 0.1s ease-in-out",
            }}
            onClick={() => setIsFormOpen((prev) => !prev)}
          >
            {">"}
          </button>
        </div>
      </div>

      <div
        className={`${
          isFormOpen ? "block" : "hidden"
        } w-full border rounded p-4 bg-white`}
      >
        <>
          {/* Add tow input to add the title to the form and the text of the button  */}
          <div className="mb-2">
            <label className="block text-sm font-medium">Title:</label>
            <input
              type="text"
              className="px-2 py-1 border rounded w-full text-sm"
              value={formData.title}
              onChange={(e) =>
                setFormData({...formData, title: e.target.value})
              }
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Button Text:</label>
            <input
              type="text"
              className="px-2 py-1 border rounded w-full text-sm"
              value={formData.buttonText}
              onChange={(e) =>
                setFormData({...formData, buttonText: e.target.value})
              }
            />
          </div>
        </>

        {formData?.form?.length <= 0 && (
          <p className="text-xs text-center text-gray-500 ml-2">
            (Click in the + button to add a new section)
          </p>
        )}
        {formData?.form?.map((section, sectionIndex) => (
          <div
            key={sectionIndex}
            className="mb-8 border p-4 rounded bg-gray-50"
          >
            <div className="mb-4 flex items-center justify-between">
              <div className="flex gap-1 items-center">
                <label className="block text-sm font-medium">Columns:</label>
                <select
                  value={section.columns}
                  onChange={(e) =>
                    updateSection(
                      sectionIndex,
                      "columns",
                      parseInt(e.target.value)
                    )
                  }
                  className="px-1.5 py-1 border rounded text-sm"
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>

              {/* Check if the input is radio to show the flçex direction property */}
              {/* {section.fields.some((field) => field.type === "radio") && (
              <>
                <label className="block text-sm font-medium">
                  Flex Direction:
                </label>
                <select
                  value={section.flexDirection}
                  onChange={(e) =>
                    updateSection(sectionIndex, "flexDirection", e.target.value)
                  }
                  className="px-2 py-1 border rounded"
                >
                  <option value="row">Row</option>
                  <option value="column">Column</option>
                </select>
              </>
            )} */}
              <div className="flex gap-6 items-center">
                <div className="flex gap-2 items-center ">
                  <input
                    type="checkbox"
                    checked={mirrorIsEnabled}
                    onChange={(e) => setMirrorIsEnabled(e.target.checked)}
                  />
                  <label className="text-xs font-medium">Mirror?</label>
                </div>
                <button
                  onClick={() => removeSection(sectionIndex)}
                  className="px-2.5 py-1 rounded-full flex justify-center font-semibold  items-center bg-red-500 text-white hover:bg-red-600"
                >
                  -
                </button>
              </div>
            </div>

            <div
              className={`grid gap-4 ${
                mirrorIsEnabled &&
                (section.columns === 1
                  ? "grid-cols-1"
                  : section.columns === 2
                  ? "grid-cols-2"
                  : "grid-cols-3")
              }`}
              style={{flexDirection: section.flexDirection}}
            >
              {section.fields.map((field, fieldIndex) => (
                <div key={fieldIndex} className="p-4 border rounded bg-white">
                  {/* Common Inputs */}
                  <div className="mb-2">
                    <label className="block text-sm font-medium">
                      Field Type:
                    </label>
                    <select
                      value={field.type}
                      onChange={(e) =>
                        updateField(
                          sectionIndex,
                          fieldIndex,
                          "type",
                          e.target.value
                        )
                      }
                      className="px-2 py-1 border rounded w-full"
                    >
                      <option value="input">Input</option>
                      <option value="textarea">Textarea</option>
                      <option value="select">Select</option>
                      <option value="checkbox">Checkbox</option>
                      <option value="radio">Radio</option>
                      <option value="text">Text</option>
                      <option value="divider">Divider</option>
                    </select>
                  </div>

                  {field.type !== "divider" && (
                    <div className="mb-2">
                      <label className="block text-sm font-medium">
                        Label:
                      </label>
                      <input
                        type="text"
                        value={field.label}
                        onChange={(e) =>
                          updateField(
                            sectionIndex,
                            fieldIndex,
                            "label",
                            e.target.value
                          )
                        }
                        className="px-2 py-1 border rounded w-full"
                      />
                    </div>
                  )}

                  {/* Conditional for Select */}
                  {field.type === "select" && (
                    <div className="mb-2">
                      <label className="block text-sm font-medium">
                        Select Options:
                      </label>
                      <input
                        type="text"
                        placeholder="Options (comma-separated), Delete (ctrl + del)"
                        value={field.options.map((opt) => opt.label).join(", ")}
                        onChange={(e) =>
                          updateField(
                            sectionIndex,
                            fieldIndex,
                            "options",
                            e.target.value.split(",").map((opt) => ({
                              label: opt.trim(),
                              value: opt.trim(),
                            }))
                          )
                        }
                        className="px-2 py-1 border rounded w-full"
                      />
                    </div>
                  )}

                  {/* Radio Buttons */}
                  {field.type === "radio" && (
                    <div className="mb-2">
                      <label className="block text-sm font-medium">
                        Radio Options:
                      </label>
                      <input
                        type="text"
                        placeholder="Enter options (comma-separated)"
                        value={field.options.map((opt) => opt.label).join(", ")}
                        onChange={(e) =>
                          updateField(
                            sectionIndex,
                            fieldIndex,
                            "options",
                            e.target.value.split(",").map((opt) => ({
                              label: opt.trim(),
                              value: opt.trim(),
                            }))
                          )
                        }
                        className="px-2 py-1 border rounded w-full mb-2"
                      />

                      <div className="mb-2">
                        <label className="block text-sm font-medium">
                          Flex Direction:
                        </label>
                        <select
                          value={field.flexDirection || "column"}
                          onChange={(e) =>
                            updateField(
                              sectionIndex,
                              fieldIndex,
                              "flexDirection",
                              e.target.value
                            )
                          }
                          className="px-2 py-1 border rounded w-full"
                        >
                          <option value="row">Row</option>
                          <option value="column">Column</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {/* Placeholder */}
                  {field.type !== "divider" && (
                    <div className="mb-2">
                      <label className="block text-sm font-medium">
                        Placeholder:
                      </label>
                      <input
                        type="text"
                        value={field.placeholder}
                        onChange={(e) =>
                          updateField(
                            sectionIndex,
                            fieldIndex,
                            "placeholder",
                            e.target.value
                          )
                        }
                        className="px-2 py-1 border rounded w-full"
                      />
                    </div>
                  )}

                  {/* Remove Field Button */}
                  <div className="flex justify-end">
                    <button
                      onClick={() => removeField(sectionIndex, fieldIndex)}
                      className="px-2.5 py-1 rounded-full flex justify-center font-semibold  items-center bg-red-500 text-white hover:bg-red-600"
                    >
                      -
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex mt-4">
              <button
                onClick={() => addField(sectionIndex)}
                className="px-2.5 py-1 rounded-full  font-semibold  items-center bg-green-500 text-white hover:bg-green-600"
              >
                +
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormCMS;
