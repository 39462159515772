import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {RootState} from "../store";

interface MerchantsType {
  // id: number,
  // name: string,
  // zipcode: number,
  // datacreation: string,
  // items: number,
  // lastactivity: string,
  // archiveddata?: string,
  // status: string
  // city?: string
  id: number;
  name: string;
  state: string;
  city: string;
  zip: string;
  address: string;
  fullAddress: string;
  phone: string;
  email: string;
  geoLocation: string;
  images: string | null;
  socialReview: {
    url: string;
    rating: number;
    reviews: null | any[];
  } | null;
  isGp: boolean;
  status: string;
  createdAt: string;
  itemsCount: number;
  lastActivityDate: string;
}

interface MerchantsState {
  merchants: MerchantsType[];
  merchantsName: {id: number; name: string}[];
}

// Define the initial state using that type
const initialState: MerchantsState = {
  merchants: [],
  merchantsName: [],
};

export const merchantsSlice = createSlice({
  name: "merchants",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setMerchantsName: (
      state,
      action: PayloadAction<{id: number; name: string}[]>
    ) => {
      state.merchantsName = action.payload;
    },
  },
});

export const {setMerchantsName} = merchantsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const merchantsArchivedStore = (state: RootState) => state.merchants.merchants.filter((el: any) => el.status.toLowerCase() === 'archived');
// export const merchantsStore = (state: RootState) => state.merchants.merchants.filter((el: any) => el.status.toLowerCase() !== 'archived');
export const getMerchantByID = (
  state: RootState,
  value: string | undefined
) => {
  if (typeof value !== "undefined") {
    return state.merchants.merchants.find(
      (el: any) => el.id === parseInt(value)
    );
  }
};

export default merchantsSlice.reducer;
