// @ts-nocheck
import {createApi} from "@reduxjs/toolkit/query/react";
import {store} from "store/store";
import customFetchBase from "./customFetchBase";
import {GrandPrizeI} from "./itemTypes";

// Types
// import { GrandPrizeI } from './itemTypes'

export const itemApi = createApi({
  reducerPath: "item",
  baseQuery: customFetchBase,
  tagTypes: [
    "Item",
    "ItemsMerchant",
    "GetItemName",
    "GetGrandPrizeName",
    "IndividualItem",
    "GrandPrize",
    "ItemGrandPrize",
    "IndividualGrandPrize",
    "LoyaltyProgram",
    "IndividualLoyaltyProgram",
  ],
  endpoints: (builder) => ({
    getItemsByID: builder.query({
      query: (id) => ({
        url: `/retailer-prize/prize/${id}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
    }),
    getIndividualItemByMerchantID: builder.query({
      query: ({idMerchant, idItem}) => ({
        url: `/retailer-prize/admin/retailer/${idMerchant}/prize/${idItem}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["IndividualItem"],
    }),
    getIndividualGrandPrizeByMerchantID: builder.query<any, any>({
      query: ({idMerchant, idItem}) => ({
        url: `/retailer-grand-prize/admin/retailer/${idMerchant}/prize/${idItem}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["IndividualGrandPrize"],
    }),
    getAllGrandPrize: builder.query<any, any>({
      query: (params) => ({
        url: `/retailer-grand-prize/admin/items${!params ? "/" : `${params}`}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["GrandPrize"],
    }),
    getAllItems: builder.query<any, any>({
      // @ts-ignore
      query: (params) => ({
        url: `/retailer-prize/admin/items${!params ? "/" : `${params}`}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["Item"],
    }),
    getAllItemsName: builder.query<any, any>({
      // @ts-ignore
      query: (params) => ({
        url: `/retailer-prize/admin/prize/name?limit=1000${
          !params ? "" : `${params}`
        }`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["GetItemName"],
    }),
    getAllGrandPrizeName: builder.query<any, any>({
      // @ts-ignore
      query: (params) => ({
        url: `/retailer-grand-prize/admin/prize/name?limit=1000${
          !params ? "" : `${params}`
        }`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["GetGrandPrizeName"],
    }),

    getAllGranzPrizeItems: builder.query<any, any>({
      // @ts-ignore
      query: (params) => ({
        url: `/retailer-grand-prize/admin/items${!params ? "/" : `${params}`}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["ItemGrandPrize"],
    }),
    getCategories: builder.query({
      query: () => ({
        url: `/retailer-grand-prize/admin/category`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
    }),
    changeStatusItem: builder.mutation({
      query({
        idItem,
        idMerchant,
        status,
      }: {
        idItem: number | string;
        idMerchant: number | string;
        status: string;
      }) {
        return {
          url: `/retailer-prize/admin/retailer/${idMerchant}/prize/${idItem}/state`,
          method: "POST",
          body: {
            newState: status,
          },
          headers: {
            "Auth-Token": store.getState().user.user.user.token,
          },
        };
      },
      invalidatesTags: ["Item", "IndividualItem"],
    }),
    updateItem: builder.mutation({
      query({
        idItem,
        idMerchant,
        data,
      }: {
        idItem: number | string;
        idMerchant: number | string;
        data: any;
      }) {
        return {
          url: `/retailer-prize/admin/retailer/${idMerchant}/prize/${idItem}`,
          method: "PATCH",
          body: {...data},
          headers: {
            "Auth-Token": store.getState().user.user.user.token,
          },
        };
      },
      invalidatesTags: ["Item", "IndividualItem"],
    }),
    duplicateItem: builder.mutation({
      query: ({idMerchant, idItem}) => ({
        url: `/retailer-prize/admin/retailer/${idMerchant}/prize/${idItem}/duplicate`,
        method: "POST",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
    }),
    deleteItem: builder.mutation({
      query: ({idMerchant, idItem}) => ({
        url: `/retailer-prize/admin/retailer/${idMerchant}/prize/${idItem}`,
        method: "DELETE",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["Item"],
    }),
    createPrize: builder.mutation({
      query: ({idMerchant, data}) => ({
        url: `/retailer-prize/admin/retailer/${idMerchant}/prize`,
        method: "POST",
        body: {...data},
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["Item"],
    }),
    updateImageItem: builder.mutation({
      query({data, idMerchant, idItem}: any) {
        return {
          url: `/retailer-prize/admin/retailer/${idMerchant}/prize/${idItem}/image`,
          method: "PUT",
          body: data,
          headers: {
            "Auth-Token": store.getState().user.user.user.token,
            accept: "application/json",
            // 'Content-Type': 'multipart/form-data'
          },
        };
      },
    }),
    updateGrandPrizeImageItem: builder.mutation({
      query({data, idMerchant, idItem}: any) {
        return {
          url: `/retailer-grand-prize/admin/retailer/${idMerchant}/prize/${idItem}/media`,
          method: "PUT",
          body: data,
          headers: {
            "Auth-Token": store.getState().user.user.user.token,
            accept: "application/json",
          },
        };
      },
      invalidatesTags: ["GrandPrize", "IndividualGrandPrize"],
    }),
    createGrandPrize: builder.mutation({
      query: ({idMerchant, data}) => ({
        url: `/retailer-grand-prize/admin/retailer/${idMerchant}/prize`,
        method: "POST",
        body: {...data},
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["Item, GrandPrize"],
    }),
    deleteGrandPrize: builder.mutation({
      query: ({idMerchant, idItem}) => ({
        url: `/retailer-grand-prize/admin/retailer/${idMerchant}/prize/${idItem}`,
        method: "DELETE",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["GrandPrize"],
    }),
    updateGrandPrize: builder.mutation({
      query({
        idItem,
        idMerchant,
        data,
      }: {
        idItem: number | string;
        idMerchant: number | string;
        data: any;
      }) {
        return {
          url: `/retailer-grand-prize/admin/retailer/${idMerchant}/prize/${idItem}`,
          method: "PATCH",
          body: {...data},
          headers: {
            "Auth-Token": store.getState().user.user.user.token,
          },
        };
      },
      invalidatesTags: ["GrandPrize", "IndividualGrandPrize"],
    }),
    changeStatusGrandPrize: builder.mutation({
      query: ({idMerchant, idItem, status}) => ({
        url: `/retailer-grand-prize/admin/retailer/${idMerchant}/prize/${idItem}/state`,
        method: "POST",
        body: {newState: status},
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["GrandPrize", "IndividualGrandPrize"],
    }),
    duplicateGrandPrizeItem: builder.mutation({
      query: ({idMerchant, idItem, redemptionCodes}) => ({
        url: `/retailer-grand-prize/admin/retailer/${idMerchant}/prize/${idItem}/duplicate`,
        method: "POST",
        body: {
          redemptionCodes: redemptionCodes,
        },
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["GrandPrize"],
    }),
    loadRedemptionCodeCSV: builder.mutation({
      query: (data) => ({
        url: `/retailer-grand-prize/admin/prize/redemption-codes/upload`,
        method: "POST",
        body: data,
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
    }),
    getDeclineReasons: builder.query({
      query: () => ({
        url: `/retailer-prize/admin/reason`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["IndividualItem, IndividualGrandPrize"],
    }),
    createDeclineReasons: builder.mutation({
      query: ({idRetailer, idPrize, data}) => ({
        url: `/retailer-prize/admin/retailer/${idRetailer}/prize/${idPrize}/decline`,
        body: {...data},
        method: "POST",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["IndividualItem, IndividualGrandPrize"],
    }),
    approveChanges: builder.mutation({
      query: ({idRetailer, idPrize, data}) => ({
        url: `/retailer-prize/admin/retailer/${idRetailer}/prize/${idPrize}/apply-changes`,
        body: {...data},
        method: "POST",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["IndividualItem, IndividualGrandPrize", "Item"],
    }),
    approveGPChanges: builder.mutation({
      query: ({idRetailer, idPrize, data}) => ({
        url: `/retailer-grand-prize/admin/retailer/${idRetailer}/prize/${idPrize}/apply-changes`,
        body: {...data},
        method: "POST",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["IndividualItem, IndividualGrandPrize", "Item"],
    }),
    getLoyaltyProgram: builder.query({
      query: ({id, params}) => ({
        url: `/retailer-prize/admin/loyalty/retailer/${id}${
          !params ? "" : `${params}`
        }`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      transformResponse: (response: any) => {
        // Get all the claims and redemptions from the list of rewards and concat the result and add it to the main obj
        const data = response.list;

        // Loop over data and get the claims and redemptions from each reward and join the values with "/" and add it to the main obj
        data.forEach((item: any) => {
          const claims =
            item?.rewards?.map((reward: any) => reward?.claimed)?.join("/") ||
            "-";
          const redemptions =
            item?.rewards?.map((reward: any) => reward?.redeemed)?.join("/") ||
            "-";
          item.claimed = claims;
          item.redeemed = redemptions;
        });

        return {
          ...response,
          list: data,
        };
      },
      providesTags: ["LoyaltyProgram"],
    }),
    getIndividualLoyaltyProgram: builder.query({
      query: ({idLoyalty}) => ({
        url: `/retailer-prize/admin/loyalty/${idLoyalty}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["IndividualLoyaltyProgram"],
    }),
    createLoyaltyProgram: builder.mutation({
      query: ({data}) => ({
        url: `/retailer-prize/admin/loyalty`,
        body: {...data},
        method: "POST",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["LoyaltyProgram", "IndividualLoyaltyProgram"],
    }),
    updateLoyaltyProgram: builder.mutation({
      query: ({idLoyalty, data}) => ({
        url: `/retailer-prize/admin/loyalty/${idLoyalty}`,
        body: data,
        method: "PATCH",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["LoyaltyProgram", "IndividualLoyaltyProgram"],
    }),
    approveLoyaltyProgram: builder.mutation({
      query: ({idLoyalty, data}) => ({
        url: `/retailer-prize/admin/loyalty/${idLoyalty}/apply-changes`,
        body: data,
        method: "POST",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["LoyaltyProgram", "IndividualLoyaltyProgram"],
    }),
    updateLoyaltyProgramImage: builder.mutation({
      query: ({idLoyalty, data}) => ({
        url: `/retailer-prize/admin/loyalty/${idLoyalty}/image`,
        body: data,
        method: "PUT",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["LoyaltyProgram", "IndividualLoyaltyProgram"],
    }),
    deleteLoyaltyProgram: builder.mutation({
      query: ({idLoyalty}) => ({
        url: `/retailer-prize/admin/loyalty/${idLoyalty}`,
        method: "DELETE",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["LoyaltyProgram", "IndividualLoyaltyProgram"],
    }),
    deleteRewardLoyaltyProgram: builder.mutation({
      query: ({idReward}) => ({
        url: `/retailer-prize/admin/loyalty/reward/${idReward}`,
        method: "DELETE",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["LoyaltyProgram", "IndividualLoyaltyProgram"],
    }),
    archiveLoyaltyProgram: builder.mutation({
      query: ({idLoyalty}) => ({
        url: `/retailer-prize/admin/loyalty/${idLoyalty}/archive`,
        method: "POST",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["LoyaltyProgram", "IndividualLoyaltyProgram"],
    }),
    changeStatusLoyaltyProgram: builder.mutation({
      query: ({idLoyalty, data}) => ({
        url: `/retailer-prize/admin/loyalty/${idLoyalty}/state`,
        body: data,
        method: "POST",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["LoyaltyProgram", "IndividualLoyaltyProgram"],
    }),
    createReward: builder.mutation({
      query: ({idLoyalty, data}) => ({
        url: `/retailer-prize/admin/loyalty/${idLoyalty}/reward-step`,
        body: data,
        method: "POST",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["LoyaltyProgram", "IndividualLoyaltyProgram"],
    }),
  }),
});

export const {
  // Prize & Offer
  useChangeStatusItemMutation,
  useGetAllItemsQuery,
  useGetAllItemsNameQuery,
  useLazyGetAllItemsQuery,
  useGetIndividualItemByMerchantIDQuery,
  useLazyGetIndividualItemByMerchantIDQuery,
  useCreatePrizeMutation,
  useDeleteItemMutation,
  useDuplicateItemMutation,
  useGetCategoriesQuery,
  useUpdateImageItemMutation,
  useUpdateItemMutation,
  useGetDeclineReasonsQuery,
  useCreateDeclineReasonsMutation,
  useApproveChangesMutation,
  // GrandPrize
  useGetAllGrandPrizeQuery,
  useGetIndividualGrandPrizeByMerchantIDQuery,
  useLazyGetIndividualGrandPrizeByMerchantIDQuery,
  useGetAllGrandPrizeNameQuery,
  useCreateGrandPrizeMutation,
  useDeleteGrandPrizeMutation,
  useUpdateGrandPrizeMutation,
  useChangeStatusGrandPrizeMutation,
  useDuplicateGrandPrizeItemMutation,
  useUpdateGrandPrizeImageItemMutation,
  useApproveGPChangesMutation,
  // CVS
  useLoadRedemptionCodeCSVMutation,
  // Loyalty
  useGetLoyaltyProgramQuery,
  useLazyGetIndividualLoyaltyProgramQuery,
  useCreateLoyaltyProgramMutation,
  useUpdateLoyaltyProgramMutation,
  useApproveLoyaltyProgramMutation,
  useUpdateLoyaltyProgramImageMutation,
  useDeleteLoyaltyProgramMutation,
  useDeleteRewardLoyaltyProgramMutation,
  useArchiveLoyaltyProgramMutation,
  useCreateRewardMutation,
  useChangeStatusLoyaltyProgramMutation,
} = itemApi;
