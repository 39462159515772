import {combineReducers, configureStore} from "@reduxjs/toolkit";

// Reducers
import merchantsReducer from "./merchants/merchantsSlice";
import itemsReducer from "./items/itemsSlice";
import authReducer from "./user/userSlice";
import filtersReducer from "./filters/filtersSlice";
import modalReducer from "./modal/modalSlice";
import tabsReducer from "./tabs/tabsSlice";
import currentTableDataReducer from "./table/currentTableDataSlice";

// Api
import {setupListeners} from "@reduxjs/toolkit/query";
import {authApi} from "./api/authApi";
import {merchantsApi} from "./api/merchantsApi";
import {itemApi} from "./api/itemApi";
import {adminApi} from "./api/adminApi";
import {userApi} from "./api/userApi";
import {categoriesApi} from "./api/categoriesApi";
import {locationApi} from "./api/locationApi";
import {notificationApi} from "./api/notificationApi";
import {landingsApi} from "./api/landingsApi";

// Persist data
import storage from "redux-persist/lib/storage";
import {persistReducer, persistStore} from "redux-persist";

const persistAuthConfig = {
  key: "root",
  storage,
};
const persistTabasConfig = {
  key: "tabs",
  storage,
};

const rootUserReducer = combineReducers({
  user: authReducer,
});
const rootTabsReducer = combineReducers({
  tabs: tabsReducer,
});

const persistedUserReducer = persistReducer(persistAuthConfig, rootUserReducer);
const persistedTabsReducer = persistReducer(
  persistTabasConfig,
  rootTabsReducer
);

// @ts-ignore
export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [merchantsApi.reducerPath]: merchantsApi.reducer,
    [landingsApi.reducerPath]: landingsApi.reducer,
    [itemApi.reducerPath]: itemApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    user: persistedUserReducer,
    tabs: persistedTabsReducer,
    merchants: merchantsReducer,
    items: itemsReducer,
    filters: filtersReducer,
    modal: modalReducer,
    currentTableData: currentTableDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      authApi.middleware,
      merchantsApi.middleware,
      landingsApi.middleware,
      itemApi.middleware,
      userApi.middleware,
      adminApi.middleware,
      categoriesApi.middleware,
      locationApi.middleware,
      notificationApi.middleware,
    ]),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
