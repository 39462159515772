import React from "react";
import "../../../styles/form.scss";

type Field = {
  type: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  options?: {label: string; value: string}[];
  flexDirection?: "row" | "column";
};

export type FormSection = {
  columns: number | "full";
  fields: Field[];
};

type FormProps = {
  formInfo: {
    title: string;
    buttonText: string;
    form: FormSection[];
  };
  color: string;
};

const FormBuilder: React.FC<FormProps> = ({formInfo, color}) => {
  const {form} = formInfo;

  const renderField = (field: Field, index: number) => {
    switch (field.type) {
      case "input":
      case "email":
        return (
          <div key={index} className="form-field">
            <label>{field.label}</label>
            <input
              className="form-input"
              type={field.type}
              placeholder={field.placeholder}
              required={field.required}
            />
          </div>
        );
      case "textarea":
        return (
          <div key={index} className="form-field">
            <label>{field.label}</label>
            <textarea
              className="form-input"
              placeholder={field.placeholder}
              required={field.required}
            />
          </div>
        );
      case "checkbox":
        return (
          <div key={index} className="form-field">
            <label className="flex gap-1">
              <input
                style={{
                  accentColor: color,
                  height: "1.2rem",
                  width: "1.2rem",
                }}
                type="checkbox"
              />
              <label>{field.label}</label>
            </label>
          </div>
        );
      case "radio":
        return (
          <div key={index} className="form-field">
            <label>{field.label}</label>
            <div
              style={{
                display: "flex",
                flexDirection: field.flexDirection || "column",
                gap: field.flexDirection === "row" ? "1rem" : "0.4rem",
              }}
            >
              {field.options?.map((option, idx) => (
                <div key={idx}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <input
                      type="radio"
                      name={field.label}
                      value={option.value}
                      required={field.required}
                      style={{
                        accentColor: color,
                        height: "1.5rem",
                        width: "1.5rem",
                      }}
                    />{" "}
                    <label className="mb-0">{option.label}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      case "text":
        return (
          <div key={index} className="form-field">
            <p className="font-semibold text-sm">{field.label}</p>
          </div>
        );
      case "select":
        return (
          <div key={index} className="form-field">
            <label>{field.label}</label>
            <select>
              {field.options?.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        );

      case "divider":
        return (
          <div key={index} className="form-field divider">
            <hr />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form className="flex-1 w-full !max-w-full">
      {form.map((section, sectionIndex) => (
        <div
          key={sectionIndex}
          className={`form-section ${
            section.columns === "full"
              ? "full-width"
              : `columns-${section.columns}`
          }`}
        >
          {section.fields.map((field, fieldIndex) =>
            renderField(field, fieldIndex)
          )}
        </div>
      ))}
      <button
        type="submit"
        style={{
          backgroundColor: color,
        }}
        className="text-white font-medium"
      >
        {formInfo.buttonText}
      </button>
    </form>
  );
};

export default FormBuilder;
