// @ts-nocheck
import {createApi} from "@reduxjs/toolkit/query/react";
import {store} from "store/store";
import {customFetchLandings} from "./customFetchBase";

export const landingsApi = createApi({
  reducerPath: "landingsApi",
  baseQuery: customFetchLandings,
  tagTypes: ["Landings", "Landing"],
  endpoints: (builder) => ({
    getLandings: builder.query({
      query: () => ({
        url: `/administrator/public/landing-pages`,
        method: "GET",
      }),
      providesTags: ["Landings"],
    }),
    getLanding: builder.query({
      query: (id: string) => ({
        url: `/administrator/public/landing-pages/${id}`,
        method: "GET",
      }),
      providesTags: ["Landing"],
    }),
    createLanding: builder.mutation({
      query: (data) => ({
        url: `/administrator/public/add-landing-page`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Landings", "Landing"],
    }),
    updateLanding: builder.mutation({
      query: ({id, data}) => ({
        url: `/administrator/public/landing-pages/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Landings", "Landing"],
    }),
    deleteLanding: builder.mutation({
      query: (id) => ({
        url: `/administrator/public/landing-pages/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Landings", "Landing"],
    }),
  }),
});

export const {
  useGetLandingsQuery,
  useGetLandingQuery,
  useCreateLandingMutation,
  useUpdateLandingMutation,
  useDeleteLandingMutation,
} = landingsApi;
