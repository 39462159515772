import {setAuth, setRefetchEndpoint} from "store/user/userSlice";
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {retry} from "@reduxjs/toolkit/query/react";
import {Mutex} from "async-mutex";
import {store} from "store/store";

const baseUrl = `https://admin-panel-dev.zazmicdemo.com/v1/`;
const baseUrlLandings = `https://admin-panel.swiggleit.com/v1/`;

// Base URL Admin Panel
const baseQuery = retry(
  fetchBaseQuery({
    baseUrl,
  }),
  {
    maxRetries: 0,
  }
);

// Base URL Swiggleit Landings
const baseQueryLanding = retry(
  fetchBaseQuery({
    baseUrl: baseUrlLandings,
  }),
  {
    maxRetries: 0,
  }
);

const getNewAT = async () => {
  const refreshResult = await fetch(
    `${baseUrl}administrator/public/auth/refresh`,
    {
      method: "GET",
      headers: {
        "Refresh-Token": store.getState().user.user.user.refreshToken,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log(err));

  return refreshResult;
};

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // @ts-ignore
  if ((result.error?.originalStatus as any) === 401) {
    try {
      const refreshResult = await getNewAT();

      if (refreshResult.authToken) {
        api.dispatch(
          setAuth({
            authToken: refreshResult.authToken,
            refreshToken: refreshResult.refreshToken,
          })
        );

        api.dispatch(setRefetchEndpoint(true));
      } else {
        window.localStorage.clear();
        window.location.href = "/login";
      }
    } finally {
    }
  }

  return result;
};

const customFetchLandings: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQueryLanding(args, api, extraOptions);

  return result;
};

export {customFetchLandings};
export default customFetchBase;
