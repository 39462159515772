import {Navigate, Route, Routes} from "react-router-dom";

// Redux
import {store} from "store/store";

// Pages
import Dashboard from "page/Dashboard";
import Merchants from "page/Merchants";
import MerchantDetails from "page/Merchants/Profile/MerchantProfile";
import AddNewMerchant from "page/Merchants/Add";
import Admins from "page/Admins";
import Items from "page/Items";
import ItemsDetails from "page/Items/Details/ItemsDetails";
import GrandPrizeDetails from "page/Items/Details/GrandPrizeDetails";
import AddNewItem from "page/Items/Add";
import Users from "page/Users";
import Configuration from "page/Configuration";
import AdminProfile from "page/Admins/Profile/AdminProfile";
import UserProfile from "page/Users/Profile/UserProfile";
import AdminConfirmationReq from "components/AdminConfirmationReq";
import NotFound from "page/NotFound";

// Utils
import routes from "./routesByRole";
import Categories from "page/Categories";
import LocationDetail from "page/Configuration/Content/Location/LocationDetail";
import CategoriesDetails from "page/Categories/CategoriesDetail";
import AddLoyality from "page/Merchants/Profile/Loyality/Add";
import Notifications from "page/Notifications";
import Landings from "page/Landings";
import LandingDetails from "page/Landings/Details";
import LandingCreate from "page/Landings/Create";

function UserRoutes() {
  const userData = store.getState().user.user.user.data;

  const getRoutes = () => {
    const routeArr = [];

    if (
      userData?.role &&
      // @ts-ignore
      routes.hasOwnProperty([userData.role])
    ) {
      // @ts-ignore
      const allowedRoutes = routes[userData.role].urlAllowed;

      // Dashboard
      if (allowedRoutes.hasOwnProperty("dashboard")) {
        routeArr.push(
          <Route key="dashboard" path="/" element={<Dashboard />} />
        );
      }

      // Merchants
      if (
        allowedRoutes.hasOwnProperty("merchant") &&
        allowedRoutes.merchant.includes("view")
      ) {
        routeArr.push(
          <Route key="merchants" path="/merchants" element={<Merchants />} />
        );
        routeArr.push(
          <Route
            key="merchant_details"
            path="/merchants/:id"
            element={<MerchantDetails />}
          />
        );
      }

      if (
        allowedRoutes.hasOwnProperty("merchant") &&
        (allowedRoutes.merchant.includes("approve") ||
          allowedRoutes.merchant.includes("decline"))
      ) {
        routeArr.push(
          <Route
            key="approve_merchant"
            path="/user/:token/approve"
            element={<AdminConfirmationReq />}
          />
        );
        routeArr.push(
          <Route
            key="reject_merchant"
            path="/user/:token/decline"
            element={<AdminConfirmationReq />}
          />
        );
      }

      if (
        allowedRoutes.hasOwnProperty("merchant") &&
        allowedRoutes.merchant.includes("add")
      ) {
        routeArr.push(
          <Route
            key="merchant_create"
            path="/merchants/create"
            element={<AddNewMerchant />}
          />
        );
      }

      // Loyality Program
      if (
        allowedRoutes.hasOwnProperty("loyalty") &&
        allowedRoutes.merchant.includes("add")
      ) {
        routeArr.push(
          <Route
            key="loyality_create"
            path="/merchants/:id/loyalty"
            element={<AddLoyality />}
          />
        );
      }

      // Items
      if (
        allowedRoutes.hasOwnProperty("item") &&
        allowedRoutes.merchant.includes("view")
      ) {
        routeArr.push(<Route key="items" path="/items" element={<Items />} />);
        routeArr.push(
          <Route
            key="items_details"
            path="/items/:id"
            element={<ItemsDetails />}
          />
        );
      }

      if (
        (allowedRoutes.hasOwnProperty("item") &&
          allowedRoutes.merchant.includes("add")) ||
        (allowedRoutes.hasOwnProperty("gp") &&
          allowedRoutes.merchant.includes("add"))
      ) {
        routeArr.push(
          <Route
            key="items_create"
            path="/items/create"
            element={<AddNewItem />}
          />
        );
      }

      // GP
      if (
        allowedRoutes.hasOwnProperty("gp") &&
        allowedRoutes.merchant.includes("view")
      ) {
        routeArr.push(
          <Route
            key="gp_details"
            path="/grandprize/:id"
            element={<GrandPrizeDetails />}
          />
        );
      }

      if (
        allowedRoutes.hasOwnProperty("categories") &&
        allowedRoutes.categories.includes("view")
      ) {
        routeArr.push(
          <Route key="categories" path="/categories" element={<Categories />} />
        );
        routeArr.push(
          <Route
            key="categories_details"
            path="/categories/:id"
            element={<CategoriesDetails />}
          />
        );
      }

      if (
        allowedRoutes.hasOwnProperty("user") &&
        allowedRoutes.merchant.includes("view")
      ) {
        routeArr.push(<Route key="users" path="/users" element={<Users />} />);
        routeArr.push(
          <Route
            key="users_details"
            path="/users/:id"
            element={<UserProfile />}
          />
        );
      }

      if (
        allowedRoutes.hasOwnProperty("admin") &&
        allowedRoutes.merchant.includes("view")
      ) {
        routeArr.push(
          <Route key="admin" path="/admins" element={<Admins />} />
        );
        routeArr.push(
          <Route
            key="admin_details"
            path="/admins/:id"
            element={<AdminProfile />}
          />
        );
      }

      if (
        allowedRoutes.hasOwnProperty("config") &&
        allowedRoutes.merchant.includes("view")
      ) {
        routeArr.push(
          <Route
            path="/configuration"
            key="config"
            element={<Configuration />}
          />
        );
      }

      if (
        allowedRoutes.hasOwnProperty("config") &&
        allowedRoutes.merchant.includes("view")
      ) {
        routeArr.push(
          <Route
            path="/location/:id"
            key="locationDetail"
            element={<LocationDetail />}
          />
        );
      }

      if (
        allowedRoutes.hasOwnProperty("notifications") &&
        allowedRoutes.merchant.includes("view")
      ) {
        routeArr.push(
          <Route
            path="/notifications"
            key="notifications"
            element={<Notifications />}
          />
        );
      }

      routeArr.push(
        <Route key="landings" path="/landings" element={<Landings />} />
      );

      routeArr.push(
        <Route
          key="landingCreate"
          path="/landing/create"
          element={<LandingCreate />}
        />
      );

      routeArr.push(
        <Route key="landing" path="/landing/:id" element={<LandingDetails />} />
      );

      routeArr.push(
        <Route
          key="login"
          path="/login"
          element={<Navigate replace to="/" />}
        />
      );
      routeArr.push(<Route key="general" path="/*" element={<NotFound />} />);
    }

    return routeArr;
  };

  return <Routes>{getRoutes()}</Routes>;
}

export default UserRoutes;
